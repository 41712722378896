export function addAppPrefixToHost(url: string, base: string) {
  let urlObject;

  // If the input is just a path, construct a full URL using the base URL
  if (!/^https?:\/\//.test(url)) {
    url = new URL(url, base).toString();
  }

  try {
    urlObject = new URL(url);
  } catch (e) {
    throw new Error("Invalid URL");
  }

  const hostPrefix = "app.";

  // Check if the host already starts with "app."
  if (!urlObject.host.startsWith(hostPrefix)) {
    urlObject.host = hostPrefix + urlObject.host;
  }

  return urlObject.toString();
}

export function urlMatchesPrefixes(url: string, prefixes: string[]) {
  let urlObject;

  // Parse the URL, adding a base if it's a relative path
  if (!/^https?:\/\//.test(url)) {
    const base = window.location.origin; // Get the current host's origin
    url = new URL(url, base).toString();
  }

  try {
    urlObject = new URL(url);
  } catch (e) {
    throw new Error("Invalid URL");
  }

  const pathname = urlObject.pathname;

  // Check if the pathname matches any of the prefixes
  for (const prefix of prefixes) {
    if (pathname.includes(prefix)) {
      return true;
    }
  }

  return false;
}

export function checkAndReplace(url: string, prefixes: string[], base: string) {
  if (urlMatchesPrefixes(url, prefixes)) {
    return { replaced: true, url: addAppPrefixToHost(url, base) };
  }
  return { replaced: false, url };
}
